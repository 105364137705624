import axios, {
    AxiosInstance,
    AxiosRequestConfig,
    AxiosResponse,
    CancelTokenSource
} from 'axios';

import qs from 'qs';

import router from '@/router/index';
import store from '@/store/index';

const API_v1 = '/api/v1';

const instance: AxiosInstance = axios.create({
    baseURL: API_v1
});

// :TODO :CheckThisLater Quick hot fix for 0.1.12.2
// Сериализуем параметры в правильном формате, так как массивы по дефолту
// отдаётся в виде `param[]=1&param[]=2` которые не обрабатываются на стороне
// бекенда.
// Переопределяю сериализатор, мы заставляем его отдавать параметры в том виде
// в котором нам надо `param=1&param=2`
//  -/ n.kushnarenko 26.11.2021
//
instance.defaults.paramsSerializer = function (params) {
    return qs.stringify(params, {indices: false});
}

const client_version: String = store.getters['core/version'];

const currentExecutingRequests: {[key: string]: CancelTokenSource} = {};

instance.interceptors.request.use((request: AxiosRequestConfig) => {
        const originalRequest = request;
        const requestUrl = request.url;

        // Request URL might be undefined. So we check it.
        if (requestUrl) {
            if (currentExecutingRequests[requestUrl]) {
                const source: CancelTokenSource = currentExecutingRequests[requestUrl];
                delete currentExecutingRequests[requestUrl];
                source.cancel();
            }

            const source: CancelTokenSource = axios.CancelToken.source();
            originalRequest.cancelToken = source.token;
            currentExecutingRequests[requestUrl] = source;
        }

        const token: String = store.getters['auth/token'];
        if (token) {
            if (originalRequest.headers) {
                originalRequest.headers["Authorization"] = `Bearer ${token}`;
            }
        }

        return originalRequest;
    },
    error => {
        console.log('HTTP Request error');
        return Promise.reject(error)
    }
);

instance.interceptors.response.use(
    (response: AxiosResponse) => {
        const url = response.config.url;

        if (url) {
            if (currentExecutingRequests[url]) {
                delete currentExecutingRequests[url];
            }
        }

        // Если storage уже был объёвлен что требуется обновление
        // то не нужно делать никаких проверок.
        if (!store.getters['core/isUpdate']) {
            const server_version = response.headers.version;
            if (server_version !== undefined) {
                if (server_version !== client_version) {
                    store.dispatch('core/setUpdate', true);
                }
            }
        }

        return response;
    },
    (error) => {
        if (axios.isCancel(error)) {
            return new Promise(() => {
            });
        }

        const originalRequest = error.config;

        if (currentExecutingRequests[originalRequest.url]) {
            delete currentExecutingRequests[originalRequest.url];
        }

        if (error.response) {
            if (error.response.status === 401) {
                store.dispatch('auth/logOut');
                router.push('/login')
            }
            if (error.response.status === 402) {
                console.log("NEED PAYMENT");
                router.push('/payment_required');
            }

            if (error.response.status === 500) {
                store.dispatch("core/addNotificationError", {
                    header: "Ошибка сервера.",
                    message: "Что-то пошло не так.\nПожалуйста, попробуйте ещё раз, или оповестите техническую поддержу что вы получили эту ошибку.",
                    timeout: 20000,
                })
            }
        }

        return error.response;
    });

export type APIReturn<T> = Promise<AxiosResponse<T>>;

export default instance;
