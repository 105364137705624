import API, { APIReturn } from '@/api';

const resource = "/cameras";

interface IParamsDeleted {
    deleted: boolean
}

interface IParamsIdCamera {
    id_camera: number | null,
    deleted: boolean
}

interface ICamera {
    id: number,
    name: string,
    server: string,
    channel: string,
    sort_order: number,
    last_update: number,
    deleted: boolean,
    timezone: string,
    offset: number
}

interface ICameraCreate {
    name: string,
    server: string,
    channel: string,
    sort_order: number,
    timezone: string
}

export const CameraAPI = {
    cameraGetAll: async (options: IParamsDeleted): APIReturn<Array<ICamera>> => {
        return await API.get<Array<ICamera>>(`${resource}/`, { params: options});
    },

    cameraCreate: async (data: ICameraCreate): APIReturn<ICamera> => {
        return await API.post<ICamera>(`${resource}/`, data);
    },

    cameraUpdate: async (id_camera: number, data: ICameraCreate): APIReturn<ICamera> => {
        return await API.put<ICamera>(`${resource}/${id_camera}/`, data);
    },

    cameraDelete: async (id_camera: number): APIReturn<any> => {
        return await API.delete<any>(`${resource}/${id_camera}/`);
    },

    cameraImageGet: async (id_camera: number): APIReturn<string> => {
        return await API.get(`${resource}/${id_camera}/image/`);
    },

    cameraImageCreate: async (id_camera: number, data: any): APIReturn<string> => {
        return await API.post(`${resource}/${id_camera}/image/`, data);
    },

    cameraImageFromRecorder: async (id_camera: number): APIReturn<string> => {
        return await API.get(`${resource}/${id_camera}/image_from_recorder/`)
    }
};

interface IRecorder {
    id: number,
    name: string,
    ip: string,
    user: string,
    password: string,
    port: string,
    archive_depth: number,
    active: boolean,
    type: string,
    deleted: boolean
}

interface IRecorderCreate {
    name: string,
    ip: string,
    user: string,
    password: string,
    port: number,
    archive_depth: number,
    active: boolean
}

interface IRecorderDetect {
    ip: string,
    port: string,
    user: string,
    password: string
}

export const RecorderAPI = {
    recorderGetAll: async (): APIReturn<Array<IRecorder>> => {
        // [GET] /v1/cameras/recorders/
        return await API.get<Array<IRecorder>>(`${resource}/recorders/`);
    },

    recorderCreate: async(data: IRecorderCreate): APIReturn<IRecorder> => {
        // [POST] /v1/cameras/recorders/
        return await API.post<IRecorder>(`${resource}/recorders/`, data);
    },

    recorderUpdate: async(id: number, data: IRecorderCreate): APIReturn<IRecorder> => {
        // [PUT] /v1/cameras/recorders/:id/
        return await API.put<IRecorder>(`${resource}/recorders/${id}/`, data);
    },

    recorderDelete: async(id: number): APIReturn<string> => {
        // [DELETE] /v1/cameras/recorders/:id/
        return await API.delete<string>(`${resource}/recorders/${id}/`);
    },

    recorderDetectType: async(data: IRecorderDetect): APIReturn<string> => {
        // [PUT] /v1/cameras/recorders/detect_type/
        return await API.put<string>(`${resource}/recorders/detect_type/`, data)
    }
};

interface IAttr {
    id: number,
    system_name: string,
    name: string,
    type: string,
    default: string,
    deleted: boolean
}

interface IAttrCreate {
    system_name: string,
    name: string,
    type: string,
    default: string
}

interface ICameraAttr {
    system_name: string,
    value: string,
    type: string
}

interface ICameraAttrUpdate {
    system_name: string,
    value: string
}

export const CameraAttrAPI = {
    attrGetAll: async(): APIReturn<Array<IAttr>> => {
        // [GET] /v1/cameras/attrs/
        return await API.get<Array<IAttr>>(`${resource}/attrs/`);
    },

    attrCreate: async(data: IAttrCreate): APIReturn<IAttr> => {
        // [POST] /v1/cameras/attrs/
        return await API.post<IAttr>(`${resource}/attrs/`, data);
    },

    attrUpdate: async(id: number, data: IAttrCreate): APIReturn<IAttr> => {
        // [PUT] /v1/cameras/attrs/:id/
        return await API.put<IAttr>(`${resource}/attrs/${id}/`, data);
    },

    attrDelete: async(id: number): APIReturn<string> => {
        // [DELETE] /v1/cameras/attrs/:id/
        return await API.delete<string>(`${resource}/attrs/${id}/`);
    },

    cameraAttrGet: async (id_camera: number): APIReturn<Array<ICameraAttr>> => {
        return await API.get<Array<ICameraAttr>>(`${resource}/${id_camera}/attr/`);
    } ,

    cameraAttrUpdate: async (id_camera: number, data: ICameraAttrUpdate[]): APIReturn<any> => {
        return await API.put<any>(`${resource}/${id_camera}/attr/`, data);
    },
}

interface ICameraCoord {
    x: number,
    y: number
}

interface ICameraZone {
    id: number,
    name: string,
    system_name: string,
    id_camera: number,
    id_type: number,
    type: string,
    coords: ICameraCoord[],
    deleted: true,
    use_history: false
}

interface IZoneCreate {
    name: string,
    id_camera: number,
    id_type: number,
    coords: ICameraCoord[]
}

interface IZoneUpdate {
    name: string,
    id_camera: number,
    id_type: number,
    coords: ICameraCoord[]
}

interface IZoneHistory {
    id: number,
    name: string,
    begin_time: number,
    end_time: number,
    coords: ICameraCoord[]
}

interface IZoneHistory {
    name: string,
    times: string
}

interface IZoneAttr {
    id: number,
    system_name: string,
    name: string,
    type: string,
    default: string,
    deleted: boolean
}

interface IZoneAttrCreate {
    system_name: string,
    name: string,
    type: string,
    default: string,
}

interface IZoneAttrUpdate {
    name: string,
    type: string,
    default: string,
}

export const CameraZoneAPI = {

    zoneGetAll: async(options: IParamsIdCamera): APIReturn<Array<ICameraZone>> => {
        // [GET] v1/cameras/zones/
        return await API.get<Array<ICameraZone>>(`${resource}/zones/`, { params: options});
    },

    zoneCreate: async(data: IZoneCreate): APIReturn<ICameraZone> => {
        // [POST] v1/cameras/zones/
        return await API.post<ICameraZone>(`${resource}/zones/`, data);
    },

    zoneUpdate: async(id_zone: number, data: IZoneUpdate): APIReturn<ICameraZone> => {
        // [PUT] v1/cameras/zones/:id
        return await API.put<ICameraZone>(`${resource}/zones/${id_zone}/`, data);
    },

    zoneDelete: async(id_zone: number): APIReturn<string> => {
        // [DELETE] v1/cameras/zones/:id
        return await API.delete<string>(`${resource}/zones/${id_zone}/`);
    },

    historyGetAll: async (): APIReturn<Array<IZoneHistory>> => {
        return await API.get<Array<IZoneHistory>>(`${resource}/zones/history/`);
    },

    historyUpdate: async(id_zone: number, data: IZoneHistory): APIReturn<any> => {
        return await API.put<any>(`${resource}/zones/${id_zone}/set_name/`, data);
    },

    zoneAttrGetAll: async(): APIReturn<Array<IZoneAttr>> => {
        // [GET] /v1/cameras/zones/refs_attr/
        return await API.get<Array<IZoneAttr>>(`${resource}/zones/refs_attr/`);
    },

    zoneAttrCreate: async(data: IZoneAttrCreate): APIReturn<IZoneAttr> => {
        // [POST] /v1/cameras/zones/refs_attr/
        return await API.post<IZoneAttr>(`${resource}/zones/refs_attr/`, data);
    },

    zoneAttrUpdate: async(id: number, data: IZoneAttrUpdate): APIReturn<IZoneAttr> => {
        // [PUT] /v1/cameras/zones/refs_attr/:id/
        return await API.put<IZoneAttr>(`${resource}/zones/refs_attr/${id}/`, data);
    },

    zoneAttrDelete: async(id: number): APIReturn<any> => {
        // [DELETE] /v1/cameras/zones/refs_attr/:id/
        return await API.delete<any>(`${resource}/zones/refs_attr/${id}/`);
    },

}

interface ICameraRef {
    id: number,
    name: string
}

interface ICameraRefCreate {
    name: string
}

interface IRefZoneType {
    id: number,
    name: string,
    system_name: string,
    edition_type: number
}

interface IRefZoneTypeCreate {
    name: string,
    system_name: string,
    edition_type: number
}

interface IRefTimezone {
    name: string,
    system_name: string
}

export const CameraRefsAPI = {
    zoneNameGetAll: async(): APIReturn<Array<ICameraRef>> => {
        return await API.get<Array<ICameraRef>>(`${resource}/zones/refs_zone_name/`);
    },

    zoneNameCreate: async(data: ICameraRefCreate): APIReturn<any> => {
        return await API.post<any>(`${resource}/zones/refs_zone_name/`, data);
    },

    zoneNameUpdate: async(id_ref_zone: number, data: ICameraRefCreate): APIReturn<any> => {
        return await API.put<any>(`${resource}/zones/refs_zone_name/${id_ref_zone}/`, data);
    },

    zoneNameDelete: async(id_ref_zone: number): APIReturn<any> => {
        return await API.delete<any>(`${resource}/zones/refs_zone_name/${id_ref_zone}/`);
    },

    zoneTypeGetAll: async(): APIReturn<Array<IRefZoneType>> => {
        return await API.get<Array<IRefZoneType>>(`${resource}/zones/refs_zone_type/`);
    },

    zoneTypeCreate: async(data: IRefZoneTypeCreate): APIReturn<IRefZoneType> => {
        return await API.post<IRefZoneType>(`${resource}/zones/refs_zone_type/`, data);
    },

    timezoneGetAll: async(): APIReturn<Array<IRefTimezone>> => {
        // [GET] /v1/cameras/refs/timezone/
        return await API.get<Array<IRefTimezone>>(`${resource}/refs/timezone/`);
    }
}
